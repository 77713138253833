import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import { ReactNode } from 'react'
import styled from 'styled-components'

export type ColorTextType = keyof typeof convertedVariables
export type FontWeightType = keyof typeof FONT_WEIGHT
export type FontSizeType = keyof typeof FONT_SIZE

interface IProps {
  fontSize?: FontSizeType
  fontWeight?: FontWeightType
  opacity?: string
  colorText?: ColorTextType
  children: ReactNode
  style?: React.CSSProperties
  lineHeight?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  textAlign?: 'left' | 'center' | 'right'
  className?: string
}

const BaseText: React.FC<IProps> = ({
  colorText,
  fontSize,
  fontWeight,
  opacity,
  children,
  style,
  lineHeight,
  textAlign,
  ...props
}) => {
  return (
    <TextCustom
      $colorText={colorText && convertedVariables?.[colorText]}
      $fontWeight={fontWeight && FONT_WEIGHT?.[fontWeight]}
      $opacity={opacity}
      $fontSize={fontSize && FONT_SIZE?.[fontSize]}
      style={style}
      $lineHeight={lineHeight}
      $textAlign={textAlign}
      {...props}
    >
      {children}
    </TextCustom>
  )
}

export const TextCustom = styled.div<{
  $fontSize?: string
  $fontWeight?: string
  $opacity?: string
  $colorText?: string
  $lineHeight?: string
  $textAlign?: string
}>`
  color: ${props => props?.$colorText ?? 'var(--primary-color)'};
  font-size: ${props => props?.$fontSize ?? FONT_SIZE.xxs};
  font-weight: ${props => props?.$fontWeight ?? FONT_WEIGHT.regular};
  opacity: ${props => props?.$opacity ?? '1'};
  line-height: ${props => props?.$lineHeight};
  text-align: ${props => props?.$textAlign ?? 'left'}
`
export default BaseText
