import R from 'app/assets/R'

import { requestExportExcelReport24 } from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import {
  DefinePaymentMethod,
  EPaymentMethod,
  IParamsGetReport24,
  IReport24,
} from 'app/api/report/model/report-24'
import { getDetailPatientUrl } from 'app/common/helpers'
import BaseText from 'app/components/common/BaseText'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { initPagination } from 'app/constant'
import { notificationController } from 'app/controllers/notification-controller'
import { useDebounce } from 'app/hook'
import { useGetFilterReport24 } from 'app/react-query/hook/report'
import { isEmpty, isEqual } from 'lodash'
import {
  DateUtil,
  FORMAT_DD_MM_YYYY,
  formatMoney,
  Pagination,
} from 'parkway-web-common'
import React, { ReactNode, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IDataTable } from './type'

const TitleComponent: React.FC<{
  children: ReactNode
  textAlign?: 'left' | 'right' | 'center' | undefined
}> = ({ children, textAlign }) => {
  return (
    <BaseText
      children={children}
      textAlign={textAlign}
      fontWeight="semibold"
      opacity="0.7"
    />
  )
}

export const useTable = () => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState(initPagination)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const [filter, setFilter] = useState<IParamsGetReport24>({})

  const filterDebounce = useDebounce<IParamsGetReport24>(filter, 500)

  const { data: dataInfinity, isLoading } = useGetFilterReport24({
    keyword: filterDebounce?.keyword ?? '',
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    beginOrthodonticEndDate: filterDebounce?.beginOrthodonticEndDate,
    beginOrthodonticStartDate: filterDebounce?.beginOrthodonticStartDate,
    treatmentIds: filterDebounce?.treatmentIds?.length
      ? filterDebounce?.treatmentIds
      : undefined,
    paymentMethods: filterDebounce?.paymentMethods?.length
      ? filterDebounce?.paymentMethods
      : undefined,
    patientDefaultLocations: filterDebounce?.patientDefaultLocations?.length
      ? filterDebounce?.patientDefaultLocations
      : undefined,
    buyOrthodonticsEndDate: filterDebounce?.buyOrthodonticsEndDate,
    buyOrthodonticsStartDate: filterDebounce?.buyOrthodonticsStartDate,

    endOrthodonticEndDate: filterDebounce?.endOrthodonticEndDate,
    endOrthodonticStartDate: filterDebounce?.endOrthodonticStartDate,
  })

  const dataFlatten = useMemo(() => {
    const res = dataInfinity
    return res
  }, [dataInfinity])

  const data = useMemo(() => {
    return dataFlatten?.data?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      } as IDataTable
    })
  }, [dataFlatten])

  const totalItem = useMemo(() => {
    return dataFlatten?.total ?? 0
  }, [dataFlatten])

  const handleExportExcel = async () => {
    setIsLoadingExport(true)
    try {
      const response = await requestExportExcelReport24({
        keyword: filterDebounce?.keyword ?? '',
        beginOrthodonticEndDate: filterDebounce?.beginOrthodonticEndDate,
        beginOrthodonticStartDate: filterDebounce?.beginOrthodonticStartDate,
        treatmentIds: filterDebounce?.treatmentIds?.length
          ? filterDebounce?.treatmentIds
          : undefined,
        paymentMethods: filterDebounce?.paymentMethods?.length
          ? filterDebounce?.paymentMethods
          : undefined,
        patientDefaultLocations: filterDebounce?.patientDefaultLocations?.length
          ? filterDebounce?.patientDefaultLocations
          : undefined,
        
        buyOrthodonticsEndDate: filterDebounce?.buyOrthodonticsEndDate,
        buyOrthodonticsStartDate: filterDebounce?.buyOrthodonticsStartDate,
        endOrthodonticEndDate: filterDebounce?.endOrthodonticEndDate,
        endOrthodonticStartDate: filterDebounce?.endOrthodonticStartDate,
      })

      if (
        isEqual(response?.msgcode, ReportMessageCode.Report24.successExportData)
      ) {
        window.open(response?.data?.url, '_blank', 'noopener,noreferrer')

        notificationController?.success({
          message: t(R.strings.export_excel_success),
        })
      }
    } catch (error) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.with_error_please_contact_with_admin),
      })
    } finally {
      setIsLoadingExport(false)
    }
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.patient_code)}
            textAlign="left"
          />
        ),
        key: 'patientCode',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'medium-column',
        render: (value?: string) => {
          return <BaseText fontWeight="medium" children={value} opacity="0.5" />
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent children={t(R.strings.customer_name)} textAlign="left" />
        ),
        key: 'patientName',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record: IReport24) => {
          return (
            <RenderValueTable
              value={record?.patientName ?? '-'}
              type="OtherLink"
              hrefOtherLink={getDetailPatientUrl(record?.patientId)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.package_name)}
            textAlign="left"
          />
        ),
        key: 'treatmentName',
        className: 'text-align-left-column',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (value?: string) => {
          return <BaseText fontWeight="medium" children={value} opacity="0.5" />
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_buy_orthodontic_date_1)}
            textAlign="center"
          />
        ),
        key: 'buyOrthodonticsDate',
        className: 'money-column',
        render: (value?: Date) => {
          return (
            <BaseText
              fontWeight="medium"
              children={
                isEmpty(value)
                  ? '-'
                  : DateUtil.formatDate(value ?? '', FORMAT_DD_MM_YYYY)
              }
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_treatment_location_name)}
            textAlign="center"
          />
        ),
        key: 'treatmentLocationName',
        className: 'text-align-center-column',
        render: (value?: string) => {
          return (
            <BaseText
              fontWeight="medium"
              children={value}
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_patient_default_location)}
            textAlign="center"
          />
        ),
        key: 'patientDefaultLocation',
        className: 'text-align-center-column',
        render: (value?: string) => {
          return (
            <BaseText
              fontWeight="medium"
              children={value}
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.payment_method)}
            textAlign="left"
          />
        ),
        key: 'paymentMethod',
        className: 'text-align-center-column',
        render: (value: EPaymentMethod) => {
          return (
            <BaseText
              fontWeight="medium"
              children={DefinePaymentMethod?.[value]?.text}
              opacity="0.5"
              textAlign="left"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_treatment_price)}
            textAlign="center"
          />
        ),
        key: 'treatmentPrice',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_marketing_discount_percent)}
            textAlign="center"
          />
        ),
        key: 'marketingDiscountPercent',
        // classNameWidthColumnOverwrite: 'medium-column',
        className: 'percent-column-1',

        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, '%', undefined, 2)}
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_real_discount_percent)}
            textAlign="left"
          />
        ),
        key: 'realDiscountPercent',
        className: 'percent-column-1',
        // classNameWidthColumnOverwrite: 'medium-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, '%', undefined, 2)}
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(
              R.strings
                .report_24_content_treatment_price_before_payment_discount,
            )}
            textAlign="center"
          />
        ),
        key: 'treatmentPriceBeforePaymentDiscount',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_payment_discount_percent)}
            textAlign="center"
          />
        ),
        key: 'paymentDiscountPercent',
        className: 'percent-column-2',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, '%', undefined, 2)}
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_payment_discount_amount)}
            textAlign="center"
          />
        ),
        key: 'paymentDiscountAmount',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_treatment_final_price)}
            textAlign="center"
          />
        ),
        key: 'treatmentFinalPrice',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_installment_first_fee)}
            textAlign="center"
          />
        ),
        key: 'installmentFirstFee',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_installment_period_fee)}
            textAlign="center"
          />
        ),
        key: 'installmentPeriodFee',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_payment_for_orthodontics)}
            textAlign="center"
          />
        ),
        key: 'paymentForOrthodontics',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_patients_debts_remain)}
            textAlign="center"
          />
        ),
        key: 'paymentForOrthodonticsDebt',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_patients_debs_total_paid)}
            textAlign="center"
          />
        ),
        key: 'patientsDebsTotalPaid',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_patients_debts_remain_1)}
            textAlign="center"
          />
        ),
        key: 'patientsDebtsRemain',
        className: 'money-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_today_payment_orthodontics)}
            textAlign="center"
          />
        ),
        key: 'todayPaymentOrthodontics',
        className: 'money-column-1',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, 'đ')}
              opacity="0.5"
              textAlign="right"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_begin_orthodontic_date)}
            textAlign="center"
          />
        ),
        key: 'beginOrthodonticDate',
        className: 'money-column',
        render: (value?: Date) => {
          return (
            <BaseText
              fontWeight="medium"
              children={
                isEmpty(value)
                  ? '-'
                  : DateUtil.formatDate(value ?? '', FORMAT_DD_MM_YYYY)
              }
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_end_orthodontic_date)}
            textAlign="center"
          />
        ),
        key: 'endOrthodonticDate',
        className: 'money-column',

        render: (value?: Date) => {
          return (
            <BaseText
              fontWeight="medium"
              children={
                isEmpty(value)
                  ? '-'
                  : DateUtil.formatDate(value ?? '', FORMAT_DD_MM_YYYY)
              }
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: (
          <TitleComponent
            children={t(R.strings.report_24_content_orthodontic_month)}
            textAlign="center"
          />
        ),
        key: 'orthodonticMonth',
        className: 'month-column',
        render: (value?: number) => {
          return (
            <BaseText
              fontWeight="medium"
              children={formatMoney(value ?? 0, '')}
              opacity="0.5"
              textAlign="center"
            />
          )
        },
      }),
    ]
    return option
  }, [t, filterDebounce])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IParamsGetReport24) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data,
    columns,
    filter,
    isLoading,
    handleTableChange,
    pagination: {
      ...pagination,
      total: totalItem,
    },
    totalItem: totalItem,
    handleChangeFilter,
    handleExportExcel,
    isLoadingExport,
  }
}
