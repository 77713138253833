// Authentication
export const AUTH_PATH = '/auth'
export const LOGOUT_PATH = '/logout'
export const LOGIN_PATH = 'login'
export const AUTH_LOGIN_PATH = '/auth/login'

// Main
export const DASHBOARD_PATH = '/'
export const DASHBOARD_DOCTOR_PATH = '/dashboard-doctor'
export const DASHBOARD_BOD_PATH = '/dashboard-bod'
export const DASHBOARD_NURSE_PATH = '/dashboard-nurse'
export const DASHBOARD_CONSULTATION_PATH = '/dashboard-consultation'
export const WORK_SCHEDULE_PATH = '/work-schedule'
export const ATTENDANCE_RECORD_PATH = '/attendance-record'
export const MAILBOX_PATH = '/mailbox'
export const MY_REQUESTS_PATH = '/my-requests'
export const MY_TASKS_PATH = '/my-tasks'
export const MY_INCOME_PATH = '/my-income'
export const MEDICAL_EXAMINATION_PATH = '/medical-examination'
export const FINANCE_PATH = '/finance'
export const PURCHASING_MANAGEMENT_PATH = '/purchasing-management'
export const CLINIC_MANAGEMENT_PATH = '/clinic-management'

// my desk
export const PROMOTION_ON_GOING_PATH = '/promotion-ongoing'

// start company
export const COMPANY_PATH = 'company'
export const ORGANIZATIONAL_CHART_PATH = 'organizational-chart'
export const ANNOUNCEMENT_PATH = 'announcement'
export const INTERNAL_NEWS_PATH = 'internal-news'
export const ONBOARDING_PATH = 'onboarding'
export const COMPANY_ORGANIZATIONAL_CHART_PATH = '/company/organizational-chart'
export const COMPANY_ANNOUNCEMENT_PATH = '/company/announcement'
export const COMPANY_INTERNAL_NEWS_PATH = '/company/internal-news'
export const COMPANY_ONBOARDING_PATH = '/company/onboarding'
// end company

// report
export const REPORT_PATH = '/report'
export const REPORT_001_FULL_PATH = '/report/report-001'
export const REPORT_002_FULL_PATH = '/report/report-002'
export const REPORT_003_FULL_PATH = '/report/report-003'
export const REPORT_004_FULL_PATH = '/report/report-004'
export const REPORT_005_FULL_PATH = '/report/report-005'
export const REPORT_006_FULL_PATH = '/report/report-006'
export const REPORT_007_FULL_PATH = '/report/report-007'
export const REPORT_008_FULL_PATH = '/report/report-008'
export const REPORT_009_FULL_PATH = '/report/report-009'
export const REPORT_010_FULL_PATH = '/report/report-010'
export const REPORT_011_FULL_PATH = '/report/report-011'
export const REPORT_012_FULL_PATH = '/report/report-012'
export const CUSTOMER_SURVEY_REPORT_PATH = '/report/survey_report'
export const REPORT_014_FULL_PATH = '/report/report-14'
export const REPORT_REFERER_FULL_PATH = '/report/referer-report'
export const REPORT_015_FULL_PATH = '/report/report-15'
export const REPORT_016_FULL_PATH = '/report/report-16'
export const REPORT_018_FULL_PATH = '/report/report-18'
export const REPORT_020_FULL_PATH = '/report/report-20'
export const REPORT_021_FULL_PATH = '/report/report-21'
export const REPORT_024_FULL_PATH = '/report/report-24'

// marketing
export const MARKETING_PATH = '/marketing'
export const MARKETING_IMPORT_PLAN = '/marketing/marketing-plan'
export const MARKETING_REPORT_001_FULL_PATH = '/marketing/report-001'
export const MARKETING_REPORT_006_FULL_PATH = '/marketing/report-006'
export const MARKETING_REPORT_007_FULL_PATH = '/marketing/report-007'
export const MARKETING_REPORT_008_FULL_PATH = '/marketing/report-008'
export const MARKETING_REPORT_009_FULL_PATH = '/marketing/report-009'
export const MARKETING_REPORT_010_FULL_PATH = '/marketing/report-010'
export const MARKETING_REPORT_011_FULL_PATH = '/marketing/report-011'
export const MARKETING_CAMPAIGN_PATH = '/marketing/discount-campaign-management'
export const MARKETING_CREATE_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/create-campaign'
export const MARKETING_UPDATE_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/update-campaign'
export const MARKETING_CLONE_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/clone-campaign'
export const MARKETING_DETAIL_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/detail-campaign'
export const MARKETING_ADDITIONAL_SERVICE_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/additional-campaign'
export const MARKETING_ADDITIONAL_BILL_DISCOUNT_CAMPAIGN_PATH =
  '/marketing/discount-campaign-management/additional-bill-discount'

export const MARKETING_VOUCHER_PATH =
  '/marketing/discount-campaign-management/voucher-management'
export const MARKETING_CREATE_VOUCHER_PATH =
  '/marketing/discount-campaign-management/voucher-management/create-voucher'
export const MARKETING_DETAIL_VOUCHER_PATH =
  '/marketing/discount-campaign-management/voucher-management/detail-voucher'
export const MARKETING_LIST_VOUCHER_CODE_PATH =
  '/marketing/discount-campaign-management/voucher-management/voucher-code-list'
export const MARKETING_REFERER_REPORT_PATH = '/marketing/referer-report'
export const MARKETING_ADDITIONAL_SERVICE_VOUCHER_PATH =
  '/marketing/discount-campaign-management/voucher-management/additional-service'
export const LIMIT_DISCOUNT_MANAGEMENT_PATH =
  '/marketing/limit-discount-management'
export const MARKETING_LIST_REFERRAL_CODE_PATH =
  '/marketing/referral-voucher-code-list'
export const MARKETING_REFERRAL_VOUCHER_USER_STATISTICS =
  '/marketing/referral-voucher-user-statistics'

export const MARKETING_FIND_CAMPAIGN_WITH_CODE =
  '/marketing/find-campaign-by-voucher-code'

// customer care
export const CUSTOMER_CARE_PATH = '/customer-care'
export const CUSTOMER_CARE_PLAN_PATH = '/customer-care/customer-care-plan'
export const CUSTOMER_CARE_SURVEY_REPORT_PATH = '/customer-care/survey-report'
export const CUSTOMER_CARE_CHECK_IN_PLAN_PATH =
  '/customer-care/check-in-planing'
export const CUSTOMER_CARE_CREATE_APPOINTMENT_PATH =
  '/customer-care/create-appointment'
export const CUSTOMER_CARE_PROCESS_APPOINTMENT_REQUESTS_PATH =
  '/customer-care/process-appointment-requests'
export const CUSTOMER_CARE_CHECK_IN_SERVICE_PLAN_PATH =
  '/customer-care/check-in-service-plan'
export const CUSTOMER_BOOKING_PATH = '/customer-care/booking'
export const CUSTOMER_BOOKING_FOR_EMPLOYEE_PATH =
  '/customer-care/booking-employee'

export const CUSTOMER_CARE_SEARCH_APPOINTMENT_PATH =
  '/customer-care/search-appointment'

export const CUSTOMER_CARE_BOOKING_WEBSITE_REPORT_PATH =
  '/customer-care/booking-website-report'

// accounting
export const ACCOUNTING_PATH = '/accounting'
export const ACCOUNTING_SOLD_SELLING_EXPENSES =
  '/accounting/cost-of-goods-sold-and-selling-expenses'
export const MANAGE_CUSTOMER_DEBT_PATH = '/accounting/manage-customer-debt'
export const MANAGE_CUSTOMER_DEBT_CONTRACT_PATH =
  '/accounting/manage-customer-debt-contract'
export const ACCOUNTING_DAILY_INVOICE_PATH = '/accounting/daily-invoice'
export const ACCOUNTING_ITEM_MANAGEMENT_PATH = '/accounting/item-management'

export const ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH =
  '/accounting/item-management/add/:type'

export const ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH =
  '/accounting/item-management/update/:type/:id'

export const ACCOUNTING_TREATMENT_MANAGEMENT_PATH =
  '/accounting/treatment-management'

export const ACCOUNTING_TREATMENT_MANAGEMENT_ADD_PRODUCT_SERVICE_PATH =
  '/accounting/treatment-management/add'

export const ACCOUNTING_TREATMENT_MANAGEMENT_UPDATE_PRODUCT_SERVICE_PATH =
  '/accounting/treatment-management/update/:id'

export const ACCOUNTING_ITEM_MANAGEMENT_ADD_SERVICE_PATH =
  '/accounting/item-management/add-service'
export const ACCOUNTING_ITEM_MANAGEMENT_UPDATE_SERVICE =
  '/accounting/item-management/update-service'
export const ACCOUNTING_ITEM_MANAGEMENT_ADD_PRODUCT_PATH =
  '/accounting/item-management/add-product'
export const ACCOUNTING_ITEM_MANAGEMENT_UPDATE_PRODUCT =
  '/accounting/item-management/update-product'
export const ACCOUNTING_ITEM_MANAGEMENT_ADD_MATERIAL_PATH =
  '/accounting/item-management/add-material'
export const ACCOUNTING_ITEM_MANAGEMENT_UPDATE_MATERIAL =
  '/accounting/item-management/update-material'
export const ACCOUNTING_BRACES_TRAY = '/accounting/braces-tray'
export const ACCOUNTING_PATIENT_OUTDATED = '/accounting/patient-outdated'
export const ACCOUNTING_SCHEDULE_WITHOUT_PAID =
  '/accounting/orthodontic-patient-list-zero-payment'
export const ACCOUNTING_ORTHODONTIC_WITHOUT_PAID_PATH =
  '/accounting/orthodontic-patient-scheduling-density'
export const ACCOUNTING_REPORT_24_PATH = '/accounting/report-24'

// human resources
export const HUMAN_RESOURCES_PATH = '/hr'
export const JOB_TITLE_LEVEL_PATH = '/hr/job-title-level'
export const JOB_TITLE_PATH = '/hr/job-title'
export const UNIT_PATH = '/hr/unit'
export const ACCOUNT_MANAGEMENT_PATH = '/hr/account-management'
export const PROFILE_MANAGEMENT_PATH = '/hr/profile-management'
export const PROFILE_MANAGEMENT_CREATE_PATH =
  '/hr/profile-management/create-profile'
export const PROFILE_MANAGEMENT_UPDATE_PATH =
  '/hr/profile-management/update-profile/:id'
export const PROFILE_MANAGEMENT_UPDATE_NO_ID_PATH =
  '/hr/profile-management/update-profile'
export const SKILL_MANAGEMENT_PATH = '/hr/skill'
export const DEGREE_MANAGEMENT_PATH = '/hr/degree'
export const ROLE_MANAGEMENT = '/hr/role-management'
export const MONTHLY_ROSTER_PATH = '/hr/monthly-roster'
export const REPORT_LEAVE_PATH = '/hr/report-leave'
export const REPORT_DOCTOR_WORKING_HOUR_PATH = '/hr/report-doctor-work-time'
export const STRATEGIC_DOCTOR_PATH = '/hr/strategic-doctor'
export const APPROVE_DAY_OFF_PATH = '/hr/approve-day-off'

// inventory management
export const INVENTORY_MANAGEMENT_PATH = '/inventory-management'
export const INVENTORY_CATEGORY_PATH = '/inventory-management/item-category'
export const INVENTORY_PROVIDER_PATH = '/inventory-management/provider'
export const INVENTORY_MATERIAL_EXPORT_PATH =
  '/inventory-management/material-export'
export const INVENTORY_MATERIAL_IMPORT_PATH =
  '/inventory-management/material-import'
export const INVENTORY_MATERIAL_CATEGORY_PATH =
  '/inventory-management/material-category'

export const INVENTORY_SUPPLIER_PATH = '/inventory-management/supplier'

export const INVENTORY_WAREHOUSE_CATEGORY_PATH =
  '/inventory-management/warehouse-category'
export const INVENTORY_WAREHOUSE_PURCHASE_PATH =
  '/inventory-management/warehouse-purchase'
export const INVENTORY_WAREHOUSE_PURCHASE_CREATE_PATH =
  '/inventory-management/warehouse-purchase/create'
export const INVENTORY_WAREHOUSE_PURCHASE_UPDATE_PATH =
  '/inventory-management/warehouse-purchase/update/:id'
export const INVENTORY_WAREHOUSE_PURCHASE_VIEW_PATH =
  '/inventory-management/warehouse-purchase/view/:id'
export const INVENTORY_WAREHOUSE_PURCHASE_GOODS_RECEIPT_PATH =
  '/inventory-management/warehouse-purchase/goods-receipt/:id'
export const INVENTORY_WAREHOUSE_PURCHASE_RE_ORDER_PATH =
  '/inventory-management/warehouse-purchase/re-order/:id'
export const INVENTORY_WAREHOUSE_PURCHASE_PRINT_PATH =
  '/inventory-management/warehouse-purchase/print/:id'
export const INVENTORY_WAREHOUSE_INVENTORY_PATH =
  '/inventory-management/warehouse-inventory'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_PATH =
  '/inventory-management/warehouse-goods-issue'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_VIEW_PATH =
  '/inventory-management/warehouse-goods-issue/view/:id'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_CREATE_PATH =
  '/inventory-management/warehouse-goods-issue/create'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_RETURN_PATH =
  '/inventory-management/warehouse-goods-issue/return/:id'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_RECEIPT_PATH =
  '/inventory-management/warehouse-goods-issue/receipt/:id'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_PATH =
  '/inventory-management/warehouse-goods-issue-for-unit'
export const INVENTORY_WAREHOUSE_GOODS_ISSUE_FOR_UNIT_CREATE_PATH =
  '/inventory-management/warehouse-goods-issue-for-unit/create/:unitId'

// other
export const PROFILE_PATH = '/profile'

// dental treatment management
export const DENTAL_TREATMENT_MANAGEMENT_PATH = '/dental-treatment-management'
export const DENTAL_TREATMENT_PATIENT_RECORD_MANAGEMENT =
  '/dental-treatment-management/patient-record-management'
export const DENTAL_TREATMENT_PATIENT_RECORD_ADD_PATH =
  '/dental-treatment-management/patient-record-management/add-patient-record'
export const DENTAL_TREATMENT_PATIENT_RECORD_UPDATE_PATH =
  '/dental-treatment-management/patient-record-management/update-patient-record/:id'
export const DENTAL_TREATMENT_PATIENT_RECORD_PROFILE_PATH =
  '/dental-treatment-management/patient-record-management/profile-patient-record'
export const DENTAL_TREATMENT_PATIENT_PROFILE_TREATMENT_PLAN =
  '/dental-treatment-management/patient-record-management/profile-patient-record/treatment-plan'
export const DENTAL_TREATMENT_PATIENT_SCHEDULE_WITHOUT_PAID =
  '/dental-treatment-management/orthodontic-patient-list-zero-payment'
export const DENTAL_TREATMENT_PATIENT_ORTHODONTIC_WITHOUT_PAID_PATH =
  '/dental-treatment-management/orthodontic-patient-scheduling-density'
export const DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_PLAN_PATH =
  '/dental-treatment-management/approve-treatment-plan'
export const DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_STAGE_PATH =
  '/dental-treatment-management/approve-treatment-phase'
export const DENTAL_TREATMENT_MANAGEMENT_APPROVE_TREATMENT_FLOW_PATH =
  '/dental-treatment-management/approve-flow'
export const DENTAL_TREATMENT_MANAGEMENT_PATIENT_SEARCH_PATH =
  '/dental-treatment-management/patient-search'

// utilities
export const UTILITIES_PATH = '/utilities'
export const UTILITIES_GENERATE_QR_PATH = '/utilities/qr_generate'
