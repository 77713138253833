import { convertGeneralPNLValue } from './../../containers/Report/Report012/common-hook'
import { IParamsGetPNL, IResponseGetPNL } from 'app/api/report/model/report-PNL'
import { useInfiniteQuery, useQuery } from 'react-query'
import { ReportKeys } from '../query-key/report'
import {
  requestGetFilterReport04,
  requestGetPNL,
  requestGetReferralSource,
  requestGetReport15,
  requestGetReport16,
  requestGetReport18,
  requestGetReport20,
  requestGetReport21,
  requestGetReport24,
} from 'app/api/report'
import { ReportMessageCode } from 'app/api/report/constant'
import { isEqual } from 'lodash'
import { ResponseType, initialPagination } from 'parkway-web-common'
import { IDataBaseTable } from 'app/components/tables/BaseTableReport/type'
import { IGeneralPNLTable } from 'app/containers/Report/Report012/common-model'
import { IDataPNLMonthly } from 'app/containers/Report/Report012/Monthly/data'
import { IDataPNLQuarter } from 'app/containers/Report/Report012/Quarter/data'
import { getToken } from 'app/service/storage/storage-service'
import { IResponseFilterReport04 } from 'app/api/report/model/report-04'
import {
  IDateValueReportBooking,
  IParamsGetReportBookingWebsite,
  IResponseGetReportBooking,
} from 'app/api/customer-care/model/customer-care'
import { requestGetReportBookingFromWebsite } from 'app/api/customer-care'
import { CustomerCareMessageCode } from 'app/api/customer-care/constant'
import {
  IDataReport16,
  IParamsGetReport16,
  ResponseReport16Type,
} from 'app/api/report/model/report-16'
import { IResponseQueryList } from '../model/common'
import {
  IDataReport15,
  IParamsGetReport15,
  ResponseReport15Type,
} from 'app/api/report/model/report-15'
import {
  IParamsGetReport18,
  IReport018,
  ResponseReport18Type,
} from 'app/api/report/model/report-18'
import {
  IParamsGetReport20,
  IReport020,
  ResponseReport20Type,
} from 'app/api/report/model/report-20'
import {
  IParamsGetReport21,
  IReferralSource,
  IReport021,
  ResponseReferralSource,
  ResponseReport21Type,
} from 'app/api/report/model/report-21'
import {
  IParamsGetReport24,
  IReport24,
  ResponseReport24Type,
} from 'app/api/report/model/report-24'

export function useGetListPNL(params: IParamsGetPNL, enabled = true) {
  return useInfiniteQuery<
    IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>,
    IParamsGetPNL
  >(
    ReportKeys.reportPNLList(params),
    async () => {
      try {
        const response: IResponseGetPNL = await requestGetPNL(params)
        if (isEqual(response?.msgcode, ReportMessageCode.PNL.successGetData)) {
          const data = response.data?.rows ?? []
          const dataTable: IGeneralPNLTable<
            IDataPNLMonthly | IDataPNLQuarter
          >[] =
            data?.map((item, index) => {
              const newResponse: IGeneralPNLTable<
                IDataPNLMonthly | IDataPNLQuarter
              > = {
                key: index + 1,
                firstNumber: index,
                clinic: item?.clinicName,
                ...convertGeneralPNLValue(item).childs,
              }
              return newResponse
            }) ?? []

          return {
            pagination: initialPagination,
            data: dataTable,
          } as IDataBaseTable<
            IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>
          >
        }
        return {
          pagination: initialPagination,
          data: [],
        } as IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>
      } catch (error) {
        return {
          pagination: initialPagination,
          data: [],
        } as IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>
      }
    },
    {
      enabled: !!params && enabled,
    },
  )
}

export function useGetListArea(params: IParamsGetPNL, enabled = true) {
  return useInfiniteQuery<
    IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>,
    IParamsGetPNL
  >(
    ReportKeys.reportPNLList(params),
    async () => {
      try {
        const response: IResponseGetPNL = await requestGetPNL(params)
        if (isEqual(response?.msgcode, ReportMessageCode.PNL.successGetData)) {
          const data = response.data?.rows ?? []
          const dataTable: IGeneralPNLTable<
            IDataPNLMonthly | IDataPNLQuarter
          >[] =
            data?.map((item, index) => {
              const newResponse: IGeneralPNLTable<
                IDataPNLMonthly | IDataPNLQuarter
              > = {
                key: index + 1,
                firstNumber: index,
                clinic: item?.clinicName,
                ...convertGeneralPNLValue(item).childs,
              }
              return newResponse
            }) ?? []

          return {
            pagination: initialPagination,
            data: dataTable,
          } as IDataBaseTable<
            IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>
          >
        }
        return {
          pagination: initialPagination,
          data: [],
        } as IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>
      } catch (error) {
        return {
          pagination: initialPagination,
          data: [],
        } as IDataBaseTable<IGeneralPNLTable<IDataPNLMonthly | IDataPNLQuarter>>
      }
    },
    {
      enabled: !!params && enabled,
    },
  )
}

export function useGetFilterReport04(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseFilterReport04, undefined>(
    ReportKeys.listFilterReport014(),
    async () => {
      try {
        const res: ResponseType<IResponseFilterReport04> =
          await requestGetFilterReport04()
        if (isEqual(res?.msgcode, ReportMessageCode.Report04.successGetData)) {
          const data: IResponseFilterReport04 = res?.data
          return data
        }
        return {
          services: [],
        }
      } catch (error) {
        return {
          services: [],
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetReportBookingWebsite(
  params: IParamsGetReportBookingWebsite,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IDateValueReportBooking[], undefined>(
    ReportKeys.listReportBookingWebsite(params),
    async () => {
      try {
        const res: IResponseGetReportBooking =
          await requestGetReportBookingFromWebsite(params)
        if (
          isEqual(
            res?.msgcode,
            CustomerCareMessageCode.ReportBooking.successGetData,
          )
        ) {
          const data: IDateValueReportBooking[] = res?.data
          return data
        }
        return []
      } catch (error) {
        return []
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetFilterReport16(
  params?: IParamsGetReport16,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IDataReport16[]>, undefined>(
    ReportKeys.listFilterReport016(params),
    async () => {
      try {
        const res: ResponseReport16Type = await requestGetReport16(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report16.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
export function useGetFilterReport18(
  params?: IParamsGetReport18,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IReport018[]>, undefined>(
    ReportKeys.listFilterReport018(params),
    async () => {
      try {
        const res: ResponseReport18Type = await requestGetReport18(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report18.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
export function useGetFilterReport20(
  params?: IParamsGetReport20,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IReport020[]>, undefined>(
    ReportKeys.listFilterReport020(params),
    async () => {
      try {
        if (!params?.location || !params?.month || !params?.year) {
          return { data: [], total: 0 }
        }

        const res: ResponseReport20Type = await requestGetReport20(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report20.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
export function useGetFilterReport21(
  params?: IParamsGetReport21,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IReport021[]>, undefined>(
    ReportKeys.listFilterReport021(params),
    async () => {
      try {
        const res: ResponseReport21Type = await requestGetReport21(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report21.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetFilterReport24(
  params?: IParamsGetReport24,
  enabled = true,
) {
  const token = getToken()
  return useQuery<IResponseQueryList<IReport24[]>, undefined>(
    ReportKeys.listFilterReport024(params),
    async () => {
      try {
        const res: ResponseReport24Type = await requestGetReport24(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report24.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetFilterReport15(
  params?: IParamsGetReport15,
  enabled = true,
) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IDataReport15[]>, undefined>(
    ReportKeys.listFilterReport015(params),
    async () => {
      try {
        const res: ResponseReport15Type = await requestGetReport15(params)
        if (isEqual(res?.msgcode, ReportMessageCode.Report15.successGetData)) {
          return {
            data: res?.data || [],
            total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          total: 0,
        }
      } catch (error) {
        return {
          data: [],
          total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}

export function useGetReferralSource(enabled = true) {
  const token = getToken()
  return useInfiniteQuery<IResponseQueryList<IReferralSource[]>, undefined>(
    ReportKeys.listFilterReferralSource(),
    async () => {
      try {
        const res: ResponseReferralSource = await requestGetReferralSource()
        if (
          isEqual(res?.msgcode, ReportMessageCode.ReferralSource.successGetData)
        ) {
          return {
            data: res?.data || [],
            // total: res?.metadata?.totalItem,
          }
        }

        return {
          data: [],
          // total: 0,
        }
      } catch (error) {
        return {
          data: [],
          // total: 0,
        }
      }
    },
    {
      enabled: !!token && enabled,
    },
  )
}
