// Message code define
export const ReportMessageCode = {
  ReferralSource: {
    successGetData: '20000',
  },
  Report24: {
    successGetData: '20000',
    successExportData: '20000',
  },
  Report21: {
    successGetData: '20000',
    successExportData: '20000',
  },
  Report20: {
    successGetData: '80000',
    successExportData: '20000',
  },
  Report18: {
    successGetData: '20000',
    successExportExcel: '20000',
  },
  Report16: {
    successGetData: '20000',
  },
  Report15: {
    successGetData: '20000',
  },
  PNL: {
    successGetData: '20001',
  },
  Report14: {
    successGetData: '20000',
  },
  Report11: {
    successGetData: '20001',
    fail: '20003',
  },
  Report10: {
    successGetData: '20001',
  },
  Report09: {
    successGetData: '20001',
  },
  Report08: {
    successGetData: '20001',
  },
  Report07: {
    successGetData: '20001',
  },
  Report06: {
    successGetData: '20001',
  },
  Report05: {
    successGetData: '20001',
  },
  Report04: {
    successGetData: '20000',
  },
  Report02: {
    successGetData: '20001',
  },
  Report01: {
    successGetData: '20001',
  },
}

// Endpoint
export const ReportEndPoint = {
  ReferralSource: {
    requestGetData: 'report/v1/report-referral/referer',
  },
  Report24: {
    requestGetData: 'report/v1/patient-orthodontics',
    exportExcel: 'report/v1/patient-orthodontics/export',
  },
  Report21: {
    requestGetData: 'report/v1/report-referral',
    exportExcel: 'report/v1/report-referral/export',
  },
  Report20: {
    requestGetData: 'report/v1/appointment/doctor-stats',
    exportExcel: 'report/v1/appointment/doctor-stats/export',
  },
  Report18: {
    requestGetData: 'olddb/v1/voucher-log/report/customer-promotion-status',
    requestExportExcel:
      'olddb/v1/voucher-log/report/customer-promotion-status/export',
  },
  Report16: {
    requestGetData: 'report/v1/measuring-clinic/doctor/management',
  },
  Report15: {
    requestGetData: 'report/v1/doctor-reports/report15',
  },
  PNL: {
    requestGetData: 'report/v1/costplan/getReportPNL',
    excelFileReportPNL: 'report/v1/costplan/excelFileReportPNL',
  },
  Report14: {
    requestGetData: 'report/v1/treatment-revenue',
    export: 'report/v1/treatment-revenue/export',
  },
  Report11: {
    requestGetData: 'report/v1/patient-product-report/getTopPatientPayment',
    exportExcelTopPatientPayment:
      'report/v1/patient-product-report/exportExcelTopPatientPayment',
  },
  Report10: {
    requestGetData: 'report/v1/patient-report/getByArea',
    exportExcelPatient: 'report/v1/patient-report/exportExcelPatient',
  },
  Report09: {
    requestGetData: 'report/v1/patient-product-report/getPatientByProduct',
    exportExcelPatientProduct:
      'report/v1/patient-product-report/exportExcelPatientProduct',
  },
  Report08: {
    requestGetData: 'report/v1/revenue-product/getTopTenProductByArea',
    exportExcelTopTenProduct:
      'report/v1/revenue-product/exportExcelTopTenProduct',
  },
  Report07: {
    requestGetData: 'report/v1/patient-referer-report/getRevenueRefererByArea',
    exportExcelRefererByArea:
      'report/v1/patient-referer-report/exportExcelRefererByArea',
  },
  Report06: {
    requestGetData: 'report/v1/revenue-product/totalPaidBrandByProduct',
    exportExcelBrandByProduct:
      'report/v1/revenue-product/exportExcelBrandByProduct',
  },
  Report05: {
    requestGetData: 'report/v1/revenue/getTotalRevenueOfPaid',
    exportExcelRevenueOfPaid: 'report/v1/revenue/exportExcelRevenueOfPaid',
  },
  Report04: {
    requestGetData: 'report/v1/measuring-clinic',
    export: 'report/v1/measuring-clinic/export',
    filter: 'report/v1/measuring-clinic/filter',
  },
  Report02: {
    requestGetData: 'report/v1/measure-customer/report',
    export: 'report/v1/measure-customer/export',
  },
  Report01: {
    requestGetData: 'report/v1/patient-product-report/report-01',
    export: 'report/v1/patient-product-report/export-report-01',
  },
}
