import HeaderPage from 'app/components/header/components/HeaderPage'
import { BaseTableWrapper } from 'app/components/tables/BaseTableReport/styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT, PADDING } from 'parkway-web-common'
import styled from 'styled-components'

export const RootWrapper = styled.div`
  padding: 0px ${PADDING.md} ${PADDING.md} ${PADDING.md};
`

export const HeadPageWrapper = styled(HeaderPage)`
  padding-bottom: 0px !important;
`

export const BaseTableReport = styled(BaseTableWrapper)`
  .ant-table-thead > tr > th {
    font-size: ${FONT_SIZE.xxs} !important;
    font-weight: ${FONT_WEIGHT.semibold} !important;
    color: ${convertedVariables.primaryColor};
  }
  .money-column {
    min-width: 140px !important;
  }
  .money-column-1 {
    min-width: 145px !important;
  }
  .percent-column {
    min-width: 80px !important;
  }
  .percent-column-1 {
    min-width: 124px !important;
  }
  .percent-column-2 {
    min-width: 90px !important;
  }
  .month-column {
    min-width: 85px !important;
  }
`
