import { SearchOutlined } from '@ant-design/icons'
import { IDiscountCampaign, IParamsGetFindCampaign } from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { SvgFindCampaignIcon } from 'app/assets/svg-assets'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseDivider } from 'app/components/common/BaseDivider'
import { BaseEmpty } from 'app/components/common/BaseEmpty'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { Loading } from 'app/components/common/Loading'
import HeaderPage from 'app/components/header/components/HeaderPage'
import { MARKETING_DETAIL_CAMPAIGN_PATH } from 'app/components/router/route-path'
import { useDebounce } from 'app/hook'
import { useGetFindCampaignList } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'

export const FindCampaignContainer = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [params, setParams] = useState<IParamsGetFindCampaign>({
    code: '',
  })

  const paramDebounce = useDebounce(params, 500)

  const { data, isLoading } = useGetFindCampaignList({
    code: paramDebounce?.code,
  })


  const navigateDetailCampaign = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(MARKETING_DETAIL_CAMPAIGN_PATH, {
      state: { item },
    })
  }

  return (
    <Root>
      <HeaderPage title={t(R.strings.find_campaign_by_voucher_code)} />
      {isEmpty(paramDebounce?.code) ? (
        <EmptyCodeArea size={16}>
          <SvgFindCampaignIcon />

          <BaseText
            children={t(R.strings.find_campaign)}
            fontWeight="semibold"
            fontSize="md"
            textAlign="center"
          />

          <BaseText
            children={`"${t(
              R.strings.please_enter_voucher_code_into_search_box_below,
            )}"`}
            textAlign="center"
          />

          <BaseForm>
            <BaseForm.Item hiddenLabel>
              <BaseInput
                prefix={<SearchOutlined rev={undefined} />}
                placeholder={t(R.strings.enter_voucher_code)}
                className="input"
                onChange={e => setParams({ ...params, code: e.target.value })}
                value={params?.code}
                allowClear
              />
            </BaseForm.Item>
          </BaseForm>
        </EmptyCodeArea>
      ) : (
        <ListArea>
          <BaseText
            children={t(R.strings.find_campaign)}
            fontWeight="semibold"
            fontSize="md"
          />
          <BaseForm>
            <BaseForm.Item hiddenLabel>
              <BaseInput
                prefix={<SearchOutlined rev={undefined} />}
                placeholder={t(R.strings.enter_voucher_code)}
                className="input"
                onChange={e => setParams({ ...params, code: e.target.value })}
                value={params?.code}
                allowClear
              />
            </BaseForm.Item>
          </BaseForm>

          <BaseDivider style={{ marginTop: 0, marginBottom: 0 }} />

          <BaseText children={t(R.strings.campaign_name)} />

          {data?.data ? (
            <BaseRow
              className="item"
              gutter={16}
              align={'middle'}
              justify={'space-between'}
              onClick={() => navigateDetailCampaign(data?.data)}
            >
              <BaseCol>
                <BaseText children={data?.data?.name} fontWeight="medium" />
              </BaseCol>

              <BaseCol>
                <BaseButton
                  children={
                    <BaseText
                      colorText="collapseBackgroundColor"
                      children={t(R.strings.see_detail)}
                    />
                  }
                  type="link"
                  onClick={() => navigateDetailCampaign(data?.data)}
                />
              </BaseCol>
            </BaseRow>
          ) : (
            <BaseEmpty />
          )}
          {isLoading ? <Loading isAbsolute size="small" /> : null}
        </ListArea>
      )}
    </Root>
  )
}

const Root = styled(BaseSpace)`
  padding: 0px 16px;

  .input {
    min-width: 400px;
    padding: 8px 16px;
    border: 1px solid #d9d9d9;
  }
`

const EmptyCodeArea = styled(BaseSpace)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 300px);

  .input {
    min-width: 400px;
    padding: 8px 16px;
    border: 1px solid #d9d9d9;
  }
`

const ListArea = styled(BaseSpace)`
  width: 100%;
  position: relative;
  .item {
    padding: 16px;
    background-color: ${convertedVariables.backgroundColor};
    border-radius: 8px;
    cursor: pointer;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
`
