import { DefineDiscountCampaignType } from 'app/api/marketing/discount-campaign/constant'
import {
  CampaignStatusEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import R from 'app/assets/R'
import { BaseButton } from 'app/components/common/BaseButton'
import { BasePopover } from 'app/components/common/BasePopover'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { MARKETING_DETAIL_CAMPAIGN_PATH } from 'app/components/router/route-path'
import { RenderValueTable } from 'app/components/tables/BaseTableManagement/hook'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { usePagination } from 'app/hook'
import { useGetCampaignList } from 'app/react-query/hook/campaign'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import {
  DateUtil,
  PADDING,
  Pagination,
  ResponseType,
  initialPagination,
} from 'parkway-web-common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { IDataTable, IFilterDataTableReport } from './type'
import { FORMAT_DD_MM_YYYY_HH_MM } from 'app/constant'

export const useHookTable = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [pagination, setPagination] = useState(initialPagination)

  const [filter, setFilter] = useState<IFilterDataTableReport>({})

  const { flattenDataList } = usePagination()

  const { data: dataApi, isLoading } = useGetCampaignList({
    page: pagination.current,
    pageSize: pagination.pageSize,
    pagesize: pagination.pageSize,
    campaignType: filter?.campaignType,
    keyword: filter?.name,
    clinics: filter?.clinics,
    priority: filter?.priority,
    isStackedDiscount:
      filter?.applyWithOtherCampaign !== undefined
        ? filter?.applyWithOtherCampaign === true
          ? 1
          : 0
        : undefined,
    start: filter?.startDate,
    end: filter?.endDate,
    campaignStatus: filter?.campaignStatus
      ? [filter?.campaignStatus]
      : [CampaignStatusEnum.OnGoing, CampaignStatusEnum.UpComing],
  })

  const dataFlatten = useMemo(() => {
    const res: ResponseType<IDiscountCampaign[]> = flattenDataList(dataApi)
    return res
  }, [dataApi])

  const navigateDetailCampaign = (item?: IDiscountCampaign) => {
    if (!item) return
    navigate(`${MARKETING_DETAIL_CAMPAIGN_PATH}`, {
      state: { item },
    })
  }

  const columns = useMemo(() => {
    const option = [
      convertColumnTable<IDataTable>({
        title: t(R.strings.campaign_name),
        key: 'name',
        fixed: 'left',
        classNameWidthColumnOverwrite: 'very-big-column',
        render: (_, record) => {
          return (
            <RenderValueTable
              type={'Link'}
              value={record?.name}
              onClick={() => navigateDetailCampaign(record)}
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.type_of_offer),
        key: 'campaignType',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.campaignType
                  ? t(DefineDiscountCampaignType[record?.campaignType]?.keyI18n)
                  : '-'
              }
              fontWeight="semibold"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.priority),
        key: 'priority',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.priority
                  ? t(R.strings.priority_number, { number: record?.priority })
                  : '-'
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_with_other_campaign),
        key: 'isStackedDiscount',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.isStackedDiscount ? t(R.strings.yes) : t(R.strings.no)
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_payment_discount),
        key: 'isApplyPaymentDiscount',
        classNameWidthColumnOverwrite: 'big-column',
        render: (_, record) => {
          return (
            <BaseText
              children={
                record?.isApplyPaymentDiscount ? t(R.strings.yes) : t(R.strings.no)
              }
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.start_date),
        key: 'start',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.start ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.end_date),
        key: 'end',
        render: (_, record) => {
          return (
            <BaseText
              children={DateUtil.formatDate(
                record?.end ?? '',
                FORMAT_DD_MM_YYYY_HH_MM,
              )}
              fontWeight="medium"
              opacity="0.7"
            />
          )
        },
      }),
      convertColumnTable<IDataTable>({
        title: t(R.strings.apply_clinic),
        key: 'clinics',
        render: (_, record) => {
          return (
            <BasePopover
              content={
                <BaseSpace>
                  {record?.clinics?.map((item, index) => {
                    return (
                      <BaseText
                        children={item?.name}
                        key={index}
                        fontWeight="medium"
                      />
                    )
                  })}
                </BaseSpace>
              }
            >
              <NumberWrapper
                type="text"
                children={
                  <BaseText
                    children={t(R.strings.number_clinic, {
                      number: `${record?.clinics?.length}`,
                    })}
                    fontWeight="medium"
                    colorText="collapseBackgroundColor"
                  />
                }
              />
            </BasePopover>
          )
        },
      }),
    ]
    return option
  }, [t])

  const handleTableChange = (pagination: Pagination) => {
    setPagination(pagination)
  }

  const handleChangeFilter = (newFilter: IFilterDataTableReport) => {
    setFilter(newFilter)
    setPagination({ ...pagination, current: 1 })
  }

  return {
    data: dataFlatten?.data,
    columns,
    isLoading,
    filter,
    handleTableChange,
    pagination: {
      ...pagination,
      total: dataFlatten?.metadata?.totalItem ?? 0,
    },
    handleChangeFilter,
  }
}

const NumberWrapper = styled(BaseButton)`
  padding: 4px 8px;
  border-radius: ${PADDING.xl};
  background-color: ${convertedVariables.neutralBlack9Color};
`
