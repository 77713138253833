import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseTable } from 'app/components/common/BaseTable'
import {
  BORDER_RADIUS,
  FONT_SIZE,
  FONT_WEIGHT,
  PADDING,
  colors,
} from 'parkway-web-common'
import styled from 'styled-components'

const MAX_CHILDREN_SIZE = 20

export const BaseReportWrapper = styled.div`
  padding: 0px ${PADDING.md};
`

export const BaseTableWrapper = styled(BaseTable)`
  ${() => {
    let styles = ''
    for (let i = 1; i <= MAX_CHILDREN_SIZE; i++) {
      styles += `
        .ant-table-row.ant-table-row-level-${i} .ant-table-cell {
          background-color: #F9FAFB !important;
        }
      `
    }
    return styles
  }}

  & .ant-pagination-item {
    border-radius: ${BORDER_RADIUS};
    min-width: 1.75rem;
    height: 1.75rem;
    line-height: 1.75rem;
  }

  .row-overwrite-style {
    .ant-table-cell {
      border: 0.5px solid var(--neutral-black-2-color);
    }

    .ant-table-cell-row-hover {
      background: white !important;
    }

    .ant-table-cell-row-hover:has(.is-expanded) {
      background: var(--neutral-black-9-color) !important;
    }

    .ant-table-cell-row-hover:has(.is-hight-light) {
      background: var(--neutral-black-9-color) !important;
    }

    .ant-table-cell-row-hover:has(.is-expanded-1) {
      background: var(--neutral-black-16-color) !important;
    }

    .ant-table-cell-row-hover:has(.is-total-group-table) {
      background: var(--states-blue-light-color) !important;
    }

    .ant-table-cell-row-hover:has(.is-total-table) {
      background: var(--neutral-black-9-color) !important;
    }

    .ant-table-cell-row-hover:has(.is-expanded-child) {
      background: var(--neutral-black-1-color) !important;
    }
  }

  .ant-table-thead > tr > th {
    border: 0.5px solid var(--neutral-black-2-color) !important;
    text-align: center !important;
    font-size: 12px !important;
  }

  .very-big-column {
    min-width: 300px !important;
  }

  .big-column {
    min-width: 220px !important;
  }

  .normal-column {
    min-width: 170px !important;
  }

  .medium-column {
    min-width: 100px !important;
  }

  .small-column {
    min-width: 30px !important;
  }

  .number-column {
    min-width: 60px !important;
    max-width: 60px !important;
  }

  .center {
    text-align: center;
  }

  .title {
    &-right {
      text-align-last: right;
      padding-right: 24px;
    }
  }

  .flex-row {
    .ant-row {
      margin-left: 5px;
      display: inline-block;
    }
  }

  .all-column:has(.is-expanded) {
    background-color: var(--neutral-black-9-color);
  }

  .all-column:has(.is-hight-light) {
    background-color: var(--neutral-black-9-color) !important;
  }

  .all-column:has(.is-expanded-1) {
    background-color: var(--neutral-black-16-color);
  }

  .all-column:has(.is-total-group-table) {
    background-color: var(--states-blue-light-color) !important;
  }

  .all-column:has(.is-total-table) {
    background-color: var(--neutral-black-9-color) !important;
  }

  .all-column:has(.is-expanded-child) {
    background-color: var(--neutral-black-1-color);
  }

  .all-column {
    background-color: white;
  }
`

export const AreaRowWrapper = styled(BaseRow)<{ $isExpanded?: boolean }>`
  width: 100%;
  height: 100%;
  position: ${props => (props?.$isExpanded ? 'absolute' : 'unset')};
  top: ${props => (props?.$isExpanded ? PADDING.md : 'unset')};
`

export const ExpandedNotHaveChildRowWrapper = styled(BaseRow)`
  width: 100%;
`

export const AreaText = styled.div`
  margin-left: ${PADDING.xxs};
  font-weight: ${FONT_WEIGHT.bold};
  width: 80%;

  &.medium-text {
    font-weight: ${FONT_WEIGHT.medium};
  }
`

export const AreaNotExpandedReplaceIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: transparent;
`

export const RevenueTableWrapper = styled(BaseRow)<{
  $isBold?: boolean
  $isBlueText?: boolean
  colorOverwrite?: string
}>`
  align-item: center;
  font-weight: ${props =>
    props?.$isBold ? FONT_WEIGHT.bold : FONT_WEIGHT?.medium};
  color: ${props => {
    if (props?.colorOverwrite) {
      return props?.colorOverwrite
    }
    return props?.$isBlueText ? '#216AE2' : 'var(--primary-color)'
  }};
`

export const NoWrapper = styled.div`
  position: absolute !important;
  top: ${PADDING.md};
`

export const HeadingTitlePage = styled.p`
  font-size: ${FONT_SIZE.xxl};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
  padding: ${PADDING.md} 0px;
`

export const RootWrapper = styled(BaseCol)`
  background-color: ${colors.white};
  padding: ${PADDING.md};
  border-radius: ${BORDER_RADIUS};
`

export const HeadWrapper = styled(BaseRow)``

export const TitleTable = styled.span`
  font-size: ${FONT_SIZE.xs};
  color: var(--primary-color);
  font-weight: ${FONT_WEIGHT.bold};
`

export const GrowthWrapper = styled(BaseRow)<{ $upValue?: boolean }>`
  background-color: ${props =>
    props?.$upValue
      ? 'var(--states-green-light-color)'
      : ' var(--states-red-light-color)'};
  padding: 1px 2px;
  border-radius: 4px;
  width: fit-content;
  margin-left: ${PADDING.xxxs};

  .value {
    font-size: ${FONT_SIZE.xxs};
    font-weight: ${FONT_WEIGHT.semibold};
    color: ${props =>
      props.$upValue ? 'var(--states-green-color)' : 'var(--states-red-color)'};
    margin-left: ${PADDING.xxxxs};
  }
`
