import R from 'app/assets/R'
import { CampaignReferralEnum, CampaignStatusEnum, CampaignTypeEnum, DiscountOnPriceEnum } from './model'

// Message code define
export const DiscountCampaignMessageCode = {
  DiscountCampaign: {
    successGetData: '80000',
    success: '80000',
    findCampaignSuccess: '20000',
  },
}

// Endpoint
export const DiscountEndPoint = {
  DiscountCampaign: {
    requestGetData: '/olddb/v1/discounts',
    createData: '/olddb/v1/discounts',
    updateData: '/olddb/v1/discounts/:id',
    updateWithInsertOnlyData: '/olddb/v1/discounts/insert-config/:id',
    deleteData: '/olddb/v1/discounts/:id',
    deActivated: '/olddb/v1/discounts/deactivated/:id',
    requestGetDetail: '/olddb/v1/discounts/:id',
    requestCampaignAvailable: '/olddb/v1/discounts/available',
    requestImportTreatment: '/olddb/v1/discounts/import',
    requestImportDiscountCampaignForUpdate: '/olddb/v1/discounts/import/:id',
    requestGetTemplateTreatmentForUpdate: '/olddb/v1/discounts/export/template/:id',
    requestGetImportTemplate: '/olddb/v1/discounts/import/template',
    requestInsertStackedCampaign: '/olddb/v1/discounts/insert-stacked-campaign/:id',
    requestInsertEndDateCampaign: '/olddb/v1/discounts/extend-end-date/:id',
    requestFindCampaign: '/olddb/v1/discount-voucher/find-campaign',
  },
}

export const DefineDiscountCampaignType = {
  [CampaignTypeEnum.Treatments]: {
    keyI18n: R.strings.by_service,
  },
  [CampaignTypeEnum.Bundle]: {
    keyI18n: R.strings.buy_bundle,
  },
  [CampaignTypeEnum.BuyOneGetOne]: {
    keyI18n: R.strings.buy_A_discount_B,
  },
  [CampaignTypeEnum.Voucher]: {
    keyI18n: R.strings.cash_voucher,
  },
  [CampaignTypeEnum.TotalBill]: {
    keyI18n: R.strings.total_bill,
  },
}

export const DefineCampaignStatus = {
  [CampaignStatusEnum.Completed]: {
    keyI18n: R.strings.ended,
    backgroundColor: 'linear-gradient(90deg, #2F7EFF, #659FFF)',
  },
  [CampaignStatusEnum.Deactivated]: {
    keyI18n: R.strings.cancelled,
    backgroundColor: 'linear-gradient(90deg, #FF6B6B, #F44949)',
  },
  [CampaignStatusEnum.OnGoing]: {
    keyI18n: R.strings.on_going,
    backgroundColor: 'linear-gradient(90deg, #629DAA, #6DAEBD)',
  },
  [CampaignStatusEnum.UpComing]: {
    keyI18n: R.strings.up_coming,
    backgroundColor: 'linear-gradient(90deg, #FFAE49, #FF7617)',
  },
}

export const DefineCampaignReferralEnum = {
  [CampaignReferralEnum.Normal]: {
    keyI18n: R.strings.normal,
  },
  [CampaignReferralEnum.Patient]: {
    keyI18n: R.strings.patient_referral,
  },
  [CampaignReferralEnum.Staff]: {
    keyI18n: R.strings.staff_referral,
  },
  [CampaignReferralEnum.OnlyUser]: {
    keyI18n: R.strings.promotion_for_employees,
  }
}

export const DefineDiscountOnPriceEnum = {
  [DiscountOnPriceEnum.PerPrice]: {
    keyI18n: R.strings.apply_discount_on_list_price,
  },
  [DiscountOnPriceEnum.AfterDiscount]: {
    keyI18n: R.strings.apply_discount_after_promotion,
  }
}