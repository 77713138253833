import R from 'app/assets/R'
import {
  FunctionPermissionEnum,
  ModulePermissionEnum,
} from 'app/common/permission-module'
import { useVerifyPermissionAndRedirect } from 'app/common/use-verify-permission-redirect'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { useTable } from './hook'
import { FilterLayout } from './layouts'
import { BaseTableReport, HeadPageWrapper, RootWrapper } from './styles'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { BORDER_RADIUS, formatMoney } from 'parkway-web-common'
import { BasePagination } from 'app/components/common/BasePagination'
import styled from 'styled-components'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseCol } from 'app/components/common/BaseCol'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseSelect } from 'app/components/common/selects/BaseSelect'
import { SvgArrowDownSFillIcon } from 'app/assets/svg-assets'

export const Report024Container = ({
  module,
}: {
  module: ModulePermissionEnum
}) => {
  const {
    filter,
    handleChangeFilter,
    data,
    columns,
    isLoading,
    handleTableChange,
    pagination,
    handleExportExcel,
    isLoadingExport,
  } = useTable()
  const { isHavePermissionFunction } = useVerifyPermissionAndRedirect()
  return (
    <RootWrapper>
      <HeadPageWrapper
        titleI18nKey={R.strings.report_24}
        isExport={isHavePermissionFunction(
          module,
          FunctionPermissionEnum.REPORT_EXPORT_REPORT_24,
        )}
        onPressExport={handleExportExcel}
        isLoadingExport={isLoadingExport}
      />
      <BaseSpace
        style={{
          backgroundColor: convertedVariables.backgroundColor,
          borderRadius: BORDER_RADIUS,
        }}
      >
        <FilterLayout filter={filter} onChangeFilter={handleChangeFilter} />

        <BaseTableReport
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={isLoading}
          onChange={handleTableChange}
          scroll={{
            x: true,
            y: data?.length && data?.length > 10 ? 570 : undefined,
          }}
        />

        {pagination?.total ? (
          <BaseRow
            justify={'end'}
            align={'middle'}
            style={{ padding: '0px 16px 16px 16px' }}
          >
            <BaseCol>
              <BaseRow gutter={12} align={'middle'}>
                <BaseCol>
                  <BaseText
                    children={`Tổng ${formatMoney(pagination.total, '')} mục`}
                    fontWeight="semibold"
                    opacity="0.7"
                  />
                </BaseCol>
                <BaseCol>
                  <PaginationWrapper
                    total={pagination?.total}
                    pageSize={pagination.pageSize}
                    current={pagination.current}
                    onChange={(page, size) => {
                      handleTableChange({ current: page, pageSize: size })
                    }}
                    showSizeChanger={false}
                  />
                </BaseCol>

                <BaseCol>
                  <LineVertical />
                </BaseCol>

                <BaseCol>
                  <BaseRow gutter={8} align={'middle'}>
                    <BaseCol>
                      <BaseForm.Item hiddenLabel style={{ marginBottom: 0 }}>
                        <BaseSelect
                          options={[
                            { label: '10', value: 10 },
                            { label: '20', value: 20 },
                            { label: '50', value: 50 },
                            { label: '100', value: 100 },
                          ]}
                          value={pagination.pageSize}
                          onChange={value => {
                            handleTableChange({
                              current: pagination.current,
                              pageSize: +(value ?? 0),
                            })
                          }}
                          filterSort={() => 0}
                          style={{
                            minWidth: 40,
                          }}
                          suffixIcon={<SvgArrowDownSFillIcon />}
                        />
                      </BaseForm.Item>
                    </BaseCol>

                    <BaseCol>
                      <BaseText children={'/ Trang'} />
                    </BaseCol>
                  </BaseRow>
                </BaseCol>
              </BaseRow>
            </BaseCol>
          </BaseRow>
        ) : null}
      </BaseSpace>
    </RootWrapper>
  )
}

const PaginationWrapper = styled(BasePagination)`
  .ant-pagination-item {
    border: 1px solid #f0f1f3;
  }

  .ant-pagination-next {
    border: 1px solid #f0f1f3;
  }

  .ant-pagination-prev {
    border: 1px solid #f0f1f3;
  }

  .ant-pagination-item-active {
    border: 1px solid ${convertedVariables.collapseBackgroundColor};
  }
`

const LineVertical = styled.div`
  width: 1px;
  height: 15px;
  background-color: #f0f1f3;
`
