/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { StatusEnum, YesNoEnum } from 'app/common/enum'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { Dayjs } from 'dayjs'
import { useMemo, useState } from 'react'

export const useHookInfo = () => {
  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })
  const [keywordSearchArea, setKeywordSearchArea] = useState('')
  const [selectedClinic, setSelectedClinic] = useState<string[]>([])
  const [campaignType, setCampaignType] = useState<
    CampaignTypeEnum | undefined
  >(undefined)
  const [isApplyWithOtherCampaign, setIsApplyWithOtherCampaign] =
    useState<YesNoEnum>(YesNoEnum.No)
  const [stackedWithCampaigns, setStackedWithCampaigns] = useState<
    IDiscountCampaign[]
  >([])
  const [campaignApplyCommon, setCampaignApplyCommon] = useState<
    string | undefined
  >(undefined)
  const [isApplyPaymentDiscount, setIsApplyPaymentDiscount] = useState(false)

  const [oldEndDate, setOldEndDate] = useState<Dayjs | undefined>(undefined)

  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  const { flattenDataList } = usePagination()

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._oldId ?? item?._id,
        })),
    }))
  }, [areaApi])

  const onChangeClinic = (ids: string[]) => {
    setSelectedClinic(ids)
  }

  const onChangeCampaignType = (value: CampaignTypeEnum) => {
    setCampaignType(value)
  }

  const onChangeStackedWithCampaigns = (campaigns: IDiscountCampaign[]) => {
    setStackedWithCampaigns(campaigns)
  }

  const onChangeCampaignApplyCommon = (value: string) => {
    setCampaignApplyCommon(value)
  }

  const onChangeApplyPaymentDiscount = (value: boolean) => {
    setIsApplyPaymentDiscount(value)
  }

  return {
    areaList,
    isLoadingArea,
    keywordSearchArea,
    setKeywordSearchArea,
    filterTreeNode,
    onChangeClinic,
    selectedClinic,
    campaignType,
    onChangeCampaignType,
    isApplyWithOtherCampaign,
    setIsApplyWithOtherCampaign,
    onChangeStackedWithCampaigns,
    stackedWithCampaigns,
    onChangeCampaignApplyCommon,
    campaignApplyCommon,
    setOldEndDate,
    oldEndDate,
    onChangeApplyPaymentDiscount,
    isApplyPaymentDiscount,
  }
}
