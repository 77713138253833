import { IParamsGetDiscountCampaign, IParamsGetFindCampaign } from 'app/api/marketing/discount-campaign/model'

export const CampaignKeys = {
  allCampaigns: ['Campaigns'] as const,
  listCampaign: () =>
    [...CampaignKeys.allCampaigns, 'Campaigns'] as const,
  listCampaigns: (filter?: IParamsGetDiscountCampaign) =>
    [...CampaignKeys.listCampaign(), { filter }] as const,

  listFindCampaign: () =>
    [...CampaignKeys.allCampaigns, 'listFindCampaign'] as const,
  listFindCampaigns: (filter?: IParamsGetFindCampaign) =>
    [...CampaignKeys.listFindCampaign(), { filter }] as const,
}
