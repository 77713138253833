import { memo } from 'react'
import { isEqual } from 'lodash'
import { PageTitle } from 'app/components/common/PageTitle'
import R from 'app/assets/R'
import { useTranslation } from 'react-i18next'
import { FindCampaignContainer } from 'app/containers/Marketing/FindCampaign'

const FindCampaignPageCpn = () => {
  const { t } = useTranslation()
  return (
    <>
      <PageTitle>{t(R.strings.find_campaign_by_voucher_code)}</PageTitle>
      <FindCampaignContainer />
    </>
  )
}
const FindCampaignPage = memo(
  FindCampaignPageCpn,
  isEqual,
)

export default FindCampaignPage
