import { IPayLoadGetList } from 'app/common/interface'
import { ResponseType } from 'parkway-web-common'

export enum EPaymentMethod {
  FULL = 'FULL',
  INSTALLMENT_PW = 'INSTALLMENT_PW',
  INSTALLMENT_FUNDIIN = 'INSTALLMENT_FUNDIIN',
  INSTALLMENT_CREDIT = 'INSTALLMENT_CREDIT',
  INSTALLMENT = 'INSTALLMENT',
}

export const DefinePaymentMethod = {
  [EPaymentMethod.FULL]: {
    text: 'Trả full',
  },
  [EPaymentMethod.INSTALLMENT_PW]: {
    text: 'Trả góp  Parkway',
  },
  [EPaymentMethod.INSTALLMENT_FUNDIIN]: {
    text: 'Trả góp fundiin',
  },
  [EPaymentMethod.INSTALLMENT_CREDIT]: {
    text: 'Trả góp qua thẻ tín dụng',
  },
  [EPaymentMethod.INSTALLMENT]: {
    text: 'Trả góp',
  },
}

export interface IParamsGetReport24 extends IPayLoadGetList {
  treatmentIds?: string[]
  beginOrthodonticStartDate?: string
  beginOrthodonticEndDate?: string
  endOrthodonticStartDate?: string
  endOrthodonticEndDate?: string

  buyOrthodonticsStartDate?: string
  buyOrthodonticsEndDate?: string

  patientDefaultLocations?: string[]
  paymentMethods?: EPaymentMethod[]
}

export interface IReport24 {
  _id?: string
  patientId?: string
  paymentMethod?: string
  treatmentId?: string
  treatmentName?: string
  treatmentPrice?: number
  treatmentPriceBeforePaymentDiscount?: number
  treatmentFinalPrice?: number
  marketingDiscountPercent?: number
  realDiscountPercent?: number
  paymentDiscountPercent?: number
  paymentDiscountAmount?: number
  installmentFirstFee?: number
  installmentPeriodFee?: number
  orthodonticMonth?: number
  patientCode?: string
  patientName?: string
  treatmentLocationId?: string
  treatmentLocationName?: string
  patientDefaultLocation?: string
  patientDefaultLocationId?: string
  paymentForOrthodontics?: number
  patientsDebtsRemain?: number
  patientsDebsTotalPaid?: number
  beginOrthodonticDate?: Date | null
  endOrthodonticDate?: Date | null
  todayPaymentOrthodontics?: number | null
  paymentForOrthodonticsDebt?: number

  buyOrthodonticsDate?: Date | null
}

export type ResponseReport24Type = ResponseType<IReport24[]>
