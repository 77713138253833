/* eslint-disable @typescript-eslint/no-explicit-any */
import { SearchOutlined } from '@ant-design/icons'
import { IParamsGetReport24 } from 'app/api/report/model/report-24'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { notificationController } from 'app/controllers/notification-controller'
import { Dayjs } from 'dayjs'
import { t } from 'i18next'
import { Dates } from 'parkway-web-common'
import React from 'react'
import styled from 'styled-components'
import { AdvanceFilterLayout } from './layouts'

interface IProps {
  onChangeFilter?: (value: IParamsGetReport24) => void
  filter?: IParamsGetReport24
}

export const FilterLayout: React.FC<IProps> = ({ filter, onChangeFilter }) => {
  const onChangeKeyword = (keyword?: string) => {
    onChangeFilter?.({ ...filter, keyword })
  }

  const onChangeBuyOrthodonticsStartDate = (day?: Dayjs) => {
    if (
      day &&
      filter?.buyOrthodonticsEndDate &&
      day.isAfter(filter?.buyOrthodonticsEndDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChangeFilter?.({ ...filter, buyOrthodonticsStartDate: value })
  }

  const onChangeBuyOrthodonticsEndDate = (day?: Dayjs) => {
    if (
      day &&
      filter?.buyOrthodonticsStartDate &&
      day.isBefore(filter?.buyOrthodonticsStartDate)
    ) {
      notificationController?.error({
        message: t(R.strings.error),
        description: t(R.strings.start_date_must_be_before_end_date),
      })
      return
    }

    const value = day ? day?.toISOString() : undefined
    onChangeFilter?.({ ...filter, buyOrthodonticsEndDate: value })
  }

  return (
    <BaseForm>
      <RootWrapper size={0}>
        <BaseRow gutter={[16, 4]} align={'middle'} justify={'space-between'}>
          <BaseCol xl={20}>
            <BaseRow gutter={16} align={'middle'} wrap={false}>
              <BaseCol xl={6}>
                <BaseFormItem hiddenLabel style={{width: "100%"}}>
                  <BaseInput
                    prefix={<SearchOutlined rev={undefined} />}
                    value={filter?.keyword}
                    placeholder={t(R.strings.enter_keyword_search)}
                    onChange={e => onChangeKeyword(e?.target?.value)}
                    allowClear
                  />
                </BaseFormItem>
              </BaseCol>
              <BaseCol style={{minWidth: 160}}>
                <BaseText
                  children={t(R.strings.report_24_content_buy_orthodontic_date)}
                  fontWeight="semibold"
                  colorText="main2Color"
                />
              </BaseCol>
              <BaseFormItem hiddenLabel>
                <BaseDatePicker
                  $prefix={t(R.strings.from_date)}
                  $prefixWidth={120}
                  picker="date"
                  value={
                    filter?.buyOrthodonticsStartDate
                      ? Dates.getDate(filter?.buyOrthodonticsStartDate)
                      : null
                  }
                  onChange={day =>
                    onChangeBuyOrthodonticsStartDate(day as Dayjs)
                  }
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.choose_date)}
                />
              </BaseFormItem>

              <BaseCol>
                <BaseText children={'-'} fontWeight="semibold" opacity="0.7" colorText='main2Color' />
              </BaseCol>

              <BaseFormItem hiddenLabel>
                <BaseDatePicker
                  $prefix={t(R.strings.to_date)}
                  $prefixWidth={145}
                  value={
                    filter?.buyOrthodonticsEndDate
                      ? Dates.getDate(filter?.buyOrthodonticsEndDate)
                      : null
                  }
                  picker="date"
                  onChange={day => onChangeBuyOrthodonticsEndDate(day as Dayjs)}
                  allowClear
                  format={'DD-MM-YYYY'}
                  placeholder={t(R.strings.choose_date)}
                />
              </BaseFormItem>
            </BaseRow>
          </BaseCol>

          <BaseCol>
            <AdvanceFilterLayout
              filter={filter}
              onChangeFilter={onChangeFilter}
            />
          </BaseCol>
        </BaseRow>
      </RootWrapper>
    </BaseForm>
  )
}

const RootWrapper = styled(BaseSpace)`
  padding: 16px 16px 0px 16px;
`

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px !important;
  width: 200px;
`
