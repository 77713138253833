import { BaseTreeSelect } from 'app/components/common/selects/BaseTreeSelect'
import { convertedVariables } from 'app/styles/themes/themeVariables'
import { FONT_SIZE, FONT_WEIGHT } from 'parkway-web-common'
import styled from 'styled-components'

export const BaseDebtTreeSelect = styled(BaseTreeSelect)<{
  $prefix?: string
  $prefixWidth?: number
}>`
  width: 100%;
  .ant-select-selection-overflow-item {
    max-width: 80%;
  }

  .ant-select-selection-placeholder {
    padding-left: ${props =>
      props.$prefixWidth ? `${props?.$prefixWidth}px` : 0};
  }

  ${({ $prefix, $prefixWidth }) =>
    $prefix &&
    `.ant-select-selector::before {
        content: '${$prefix ?? '-'}:';
        font-size: ${FONT_SIZE.xs};
        font-weight: ${FONT_WEIGHT.medium};
        color: ${convertedVariables.primaryColor};
        opacity: 0.4;
        width: ${$prefixWidth ?? 0}px;
        align-items: center;
        display: flex;
      }`}
`

export const TreeSelectAll = styled.span`
  display: inline-block;
  color: #286fbe;
  cursor: pointer;
  margin-bottom: 10px;
  margin-left: 10px;
`
export const TreeUnSelectAll = styled.span`
  display: inline-block;
  color: #286fbe;
  cursor: pointer;
  margin-bottom: 10px;
`
