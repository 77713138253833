import dayjs from 'dayjs'
import moment from 'moment'

export const formatTimeDayjs = (
  timeString: string,
): dayjs.Dayjs | undefined => {
  try {
    return dayjs(`1970-01-01T${timeString}:00.000`)
  } catch (error) {
    console.log('error: ', timeString, error)
    return
  }
}

export const formatDateDayjs = (
  dateString: string,
): dayjs.Dayjs | undefined => {
  try {
    return dayjs(dateString)
  } catch (error) {
    console.log('error: ', dateString, error)
    return
  }
}

export const dateDayjsToString = (date: dayjs.Dayjs) => {
  try {
    return date.format('YYYY-MM-DD')
  } catch (error) {
    console.log('error: ', date, error)
    return
  }
}

export const timeDayjsToString = (time: dayjs.Dayjs) => {
  try {
    return time.format('HH:mm')
  } catch (error) {
    console.log('error: ', time, error)
    return
  }
}

export const dayjsToString = (
  date: dayjs.Dayjs,
  format = 'YYYY-MM-DD HH:mm',
) => {
  try {
    return date.format(format)
  } catch (error) {
    console.log('error: ', date, error)
    return
  }
}
export const getDaysInMonth: (month: number, year: number) => number[] = (
  month: number,
  year: number,
) => {
  // Initialize an empty array to hold the days
  const days: number[] = []

  // Get the number of days in the month
  const lastDay = new Date(year, month, 0).getDate()

  // Populate the array with the days of the month
  for (let day = 1; day <= lastDay; day++) {
    days.push(
      moment()
        .set({ date: day, month: month - 1, year: year })
        ?.day(),
    )
  }

  return days
}
