/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CampaignTypeEnum,
  IDiscountCampaign,
} from 'app/api/marketing/discount-campaign/model'
import { StatusEnum, YesNoEnum } from 'app/common/enum'
import { removeVietnameseTones } from 'app/helpers/string.helper'
import { usePagination } from 'app/hook'
import { IUnit } from 'app/model/unit.model'
import { useGetAreaList } from 'app/react-query/hook/location'
import { useMemo, useState } from 'react'
import { IFormData } from '../type'
import { FormInstance } from 'antd'
import { Dayjs } from 'dayjs'
import { isEqual } from 'lodash'

export const useHookInfo = ({ form }: { form?: FormInstance<IFormData> }) => {
  const { data: areaApi, isLoading: isLoadingArea } = useGetAreaList({
    typeid: 'area',
    status: StatusEnum.ACTIVE,
  })
  const [keywordSearchArea, setKeywordSearchArea] = useState('')
  const [selectedClinic, setSelectedClinic] = useState<string[]>([])
  const [campaignType, setCampaignType] = useState(CampaignTypeEnum.Treatments)
  const [info, setInfo] = useState<IFormData>({})
  const [isApplyWithOtherCampaign, setIsApplyWithOtherCampaign] =
    useState<YesNoEnum>(YesNoEnum.No)
  const [isApplyPaymentDiscount, setIsApplyPaymentDiscount] = useState(false)
  const [stackedWithCampaigns, setStackedWithCampaigns] = useState<
    IDiscountCampaign[]
  >([])
  const [campaignApplyCommon, setCampaignApplyCommon] = useState<
    string | undefined
  >(undefined)

  const filterTreeNode = (value?: string, treeNode?: any) => {
    return removeVietnameseTones(treeNode?.title)
      .toLowerCase()
      .includes(removeVietnameseTones(value).toLowerCase())
  }

  const { flattenDataList } = usePagination()

  const areaList = useMemo(() => {
    const res: IUnit[] = flattenDataList(areaApi)
    return res?.map(item => ({
      ...item,
      childs: item?.childs
        ?.filter(child => !!child?._oldId)
        ?.map(item => ({
          ...item,
          _id: item?._oldId ?? item?._id,
        })),
    }))
  }, [areaApi])

  const onChangeClinic = (ids: string[]) => {
    setSelectedClinic(ids)
    onChangeNameInfo(ids, 'clinics')
  }

  const onChangeCampaignType = (value: CampaignTypeEnum) => {
    setCampaignType(value)
    if (isEqual(value, CampaignTypeEnum.TotalBill)) {
      form?.setFieldsValue({
        applyWithOtherCampaign: YesNoEnum.No,
      })
      setIsApplyWithOtherCampaign(YesNoEnum.No)
    }
  }

  const onChangeNameInfo = (
    value: string | number | string[] | undefined | null | Dayjs,
    key: keyof IFormData,
  ) => {
    if (key === 'priority') {
      const newValue = value as unknown as number
      setInfo(prev => ({
        ...prev,
        [key]: newValue,
      }))

      form?.setFieldsValue({
        priority: newValue,
      })

      return
    }

    setInfo(prev => ({
      ...prev,
      [key]: value,
      priority: undefined,
    }))

    form?.setFieldsValue({
      priority: undefined,
    })
  }

  const initInfo = (data: IFormData) => {
    setInfo(data)
  }

  const onChangeStackedWithCampaigns = (campaigns: IDiscountCampaign[]) => {
    setStackedWithCampaigns(campaigns)
  }

  const onChangeCampaignApplyCommon = (value: string) => {
    setCampaignApplyCommon(value)
  }

  const onChangeApplyWithOtherCampaign = (value: YesNoEnum) => {
    setIsApplyWithOtherCampaign(value)

    if (isEqual(value, YesNoEnum.Yes)) {
      setIsApplyPaymentDiscount(true)
    }
  }

  const onChangeApplyPaymentDiscount = (value: boolean) => {
    setIsApplyPaymentDiscount(value)
  }

  return {
    areaList,
    isLoadingArea,
    keywordSearchArea,
    setKeywordSearchArea,
    filterTreeNode,
    onChangeClinic,
    selectedClinic,
    campaignType,
    onChangeCampaignType,
    info,
    onChangeNameInfo,
    initInfo,
    isApplyWithOtherCampaign,
    setIsApplyWithOtherCampaign,
    onChangeStackedWithCampaigns,
    stackedWithCampaigns,
    onChangeCampaignApplyCommon,
    campaignApplyCommon,
    onChangeApplyWithOtherCampaign,
    setCampaignApplyCommon,
    isApplyPaymentDiscount,
    onChangeApplyPaymentDiscount,
  }
}
