import { MethodIssueEnum } from 'app/api/marketing/discount-voucher/enum'
import R from 'app/assets/R'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import { BaseSwitch } from 'app/components/common/BaseSwitch'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import { getArray } from 'app/components/tables/common-table/constant'
import { MAX_CASE_ONE_SLOT_VOUCHER, MAX_COUNT_VOUCHER, REGEX_VOUCHER_CODE } from 'app/constant'
import { isEqual } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useCreateVoucherContext } from '../../../../context'
import { formatMoney } from 'parkway-web-common'

function combinations(n: number, k: number): number {
  if (n < k || n < 0 || k < 0) {
    return 0
  }

  function factorial(num: number): number {
    let result = 1
    for (let i = 2; i <= num; i++) {
      result *= i
    }
    return result
  }

  const res = factorial(n) / (factorial(k) * factorial(n - k))

  return Math.round(res)
}

export const CharacterLayout = () => {
  const { t } = useTranslation()
  const { form, methodIssueVoucher, listUserData, listReferralTargetData } =
    useCreateVoucherContext()
  const [isEditPrefix, setIsEditPrefix] = useState(false)
  const [prefixLength, setPrefixLength] = useState(6)
  const [prefix, setPrefix] = useState('')

  const handleEditPrefix = (value?: boolean) => {
    if (!value) {
      form?.setFieldsValue({
        prefix: undefined,
      })
    }

    setIsEditPrefix(!!value)
  }

  const onChangePrefixLength = (value: number) => {
    if (value < 6) {
      value = 6
    }

    if (value > 25) {
      value = 25
    }

    setPrefixLength(value)
    form?.setFieldsValue({
      prefix: undefined,
    })
  }

  const onChangePrefix = (value: string) => {
    setPrefix(value)
    form?.setFieldsValue({
      prefix: value,
    })
  }

  return (
    <BaseSpace size={16}>
      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <FormItem
          label={t(R.strings.number_of_characters)}
          required
          name="prefixLength"
          rules={[
            {
              required: true,
              message: t(R.strings.require_field, {
                field: t(R.strings.number_of_characters),
              }),
            },
            {
              type: 'number',
              min: 6,
              message: t(R.strings.minimum_field, {
                field: t(R.strings.number_of_characters),
                count: 6,
              }),
            },
            {
              type: 'number',
              max: 25,
              message: t(R.strings.maximum_field, {
                field: t(R.strings.number_of_characters),
                count: 25,
              }),
            },
          ]}
        >
          <InputNumberWrapper
            min={6}
            max={25}
            placeholder={t(R.strings.enter_number_of_characters)}
            onChange={e => onChangePrefixLength(e ? +e : 6)}
            value={prefixLength}
            step={1}
            precision={0}
          />
        </FormItem>
      ) : null}

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <BaseSpace className="custom-characters-wrapper" size={0}>
          <BaseRow gutter={8} align={'middle'}>
            <BaseCol>
              <FormItem
                hiddenLabel
                style={{ marginBottom: 0 }}
                name={'isCustomPrefix'}
              >
                <BaseSwitch
                  onChange={handleEditPrefix}
                  checked={isEditPrefix}
                />
              </FormItem>
            </BaseCol>

            <BaseCol>
              <BaseText
                children={t(R.strings.custom_first_characters)}
                fontWeight="medium"
                opacity="0.7"
              />
            </BaseCol>
          </BaseRow>
          {prefixLength > 0 && (
            <BaseRow gutter={16}>
              {isEditPrefix ? (
                <BaseCol xl={6}>
                  <FormItem
                    label={`${t(R.strings.discount_code)}:`}
                    name={'prefix'}
                    required={isEditPrefix}
                    dependencies={['isCustomPrefix, prefixLength']}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (getFieldValue('isCustomPrefix') && !value) {
                            return Promise.reject({
                              required: true,
                              message: t(R.strings.require_field, {
                                field: t(R.strings.discount_code),
                              }),
                            })
                          }

                          if (value?.length > prefixLength - 1) {
                            return Promise.reject({
                              required: true,
                              message: t(R.strings.maximum_field, {
                                field: t(R.strings.discount_code),
                                count: prefixLength - 1,
                              }),
                            })
                          }
                          return Promise.resolve()
                        },
                      }),
                      {
                        pattern: REGEX_VOUCHER_CODE,
                        message: t(R.strings.invalid_field, {
                          field: t(R.strings.discount_code),
                        }),
                      },
                    ]}
                  >
                    <BaseInput
                      showCount
                      placeholder={t(R.strings.enter_characters)}
                      maxLength={prefixLength - 1}
                      onChange={e =>
                        onChangePrefix(e?.target?.value?.toUpperCase())
                      }
                      value={prefix}
                    />
                  </FormItem>
                </BaseCol>
              ) : null}

              <BaseCol xl={isEditPrefix ? 18 : 24}>
                <FormItem
                  label={
                    !isEditPrefix ? `${t(R.strings.discount_code)}:` : undefined
                  }
                >
                  <BaseInput
                    value={getArray(prefixLength - prefix?.length)
                      .map(() => 'X')
                      .join('')}
                    disabled
                  />
                </FormItem>
              </BaseCol>
            </BaseRow>
          )}
          <BaseText children={t(R.strings.discount_code_note)} opacity="0.7" />
        </BaseSpace>
      ) : (
        <BaseSpace className="custom-characters-wrapper" size={0}>
          <FormItem
            label={`${t(R.strings.discount_code)}:`}
            name={'code'}
            required={true}
            rules={[
              {
                required: true,
                message: t(R.strings.require_field, {
                  field: t(R.strings.discount_code),
                }),
              },
              {
                min: 6,
                message: t(R.strings.minimum_field, {
                  field: t(R.strings.discount_code),
                  count: 6,
                }),
              },
              {
                max: 25,
                message: t(R.strings.maximum_field, {
                  field: t(R.strings.discount_code),
                  count: 25,
                }),
              },
              {
                pattern: REGEX_VOUCHER_CODE,
                message: t(R.strings.invalid_field, {
                  field: t(R.strings.discount_code),
                }),
              },
            ]}
          >
            <BaseInput
              placeholder={t(R.strings.enter_characters)}
              minLength={6}
              maxLength={25}
              onChange={e => {
                form?.setFieldsValue({
                  code: e?.target?.value?.toUpperCase(),
                })
              }}
            />
          </FormItem>
          <BaseText children={t(R.strings.discount_code_note)} opacity="0.7" />
        </BaseSpace>
      )}

      {isEqual(methodIssueVoucher, MethodIssueEnum.Auto) ? (
        <FormItem
          label={t(R.strings.total_voucher)}
          name={'totalVoucher'}
          required
          dependencies={['prefixLength', 'prefix', 'code', 'referralList']}
          rules={[
            {
              required: true,
              message: t(R.strings.require_field, {
                field: t(R.strings.total_voucher),
              }),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value > MAX_COUNT_VOUCHER) {
                  return Promise.reject(
                    t(R.strings.you_can_only_create_up_to, {
                      total: formatMoney(MAX_COUNT_VOUCHER, ""),
                    }),
                  )
                }

                if (listUserData?.length) {
                  if (value > listUserData.length) {
                    return Promise.reject(
                      t(R.strings.total_voucher_and_customer_import_not_equal),
                    )
                  }
                }

                const referralList =
                  getFieldValue('referralList') ?? listReferralTargetData

                if (referralList?.length) {
                  if (value > referralList.length) {
                    return Promise.reject(
                      t(R.strings.total_voucher_and_referral_import_not_equal),
                    )
                  }
                }

                const prefixLength =
                  getFieldValue('prefixLength') ??
                  getFieldValue('code')?.length ??
                  6
                const prefix = getFieldValue('prefix') ?? ''

                const lengthAvailable = combinations(
                  MAX_CASE_ONE_SLOT_VOUCHER,
                  prefixLength - prefix.length,
                )

                if (value > lengthAvailable) {
                  return Promise.reject(
                    t(R.strings.you_can_only_create_up_to, {
                      total: lengthAvailable,
                    }),
                  )
                }

                return Promise.resolve()
              },
            }),
          ]}
        >
          <InputNumberWrapper
            placeholder={t(R.strings.enter_quantity)}
            min={1}
            step={1}
            precision={0}
          />
        </FormItem>
      ) : null}
    </BaseSpace>
  )
}

const FormItem = styled(BaseForm.Item)`
  width: 100%;
  margin-bottom: 0px !important;
`

const InputNumberWrapper = styled(InputNumber)`
  width: 100%;
`
